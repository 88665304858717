import React, {useEffect, useState} from 'react';
import {S3Client, PutObjectCommand} from '@aws-sdk/client-s3';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UploadAdInput = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);

    const s3Client = new S3Client({
        region: "us-east-1",  // Specify your region
        credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
        }
    });

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            toast.error("Please select a file first.");
            return;
        }
        const fileCheck = String(selectedFile.name);
        console.log(' ACtual file name shown ', fileCheck);
        if (!fileCheck.endsWith(".csv")) {
            toast.error("Only csv files suppoerted. See sample ad file to upload");
            return;
        }
        const communityId = props.communityId;

        const uploadParams = {
            Bucket: "jack-and-jill-ads",
            Key: `${communityId}/` + selectedFile.name,
            Body: selectedFile,
            ContentType: selectedFile.type
        };

        try {
            const command = new PutObjectCommand(uploadParams);
            await s3Client.send(command);
            toast.success("File uploaded successfully to ask jill");
        } catch (error) {
            console.error("Error uploading file:", error);
            toast.error("There was an error uploading your file.");
        }
    };

    return (
        <div className="file-upload-container">
            <label className="custom-file-upload">
                <input type="file" onChange={handleFileChange}/>
                <span>{selectedFile ? selectedFile.name : "Choose File"}</span>
            </label>
            <button className="btn-add" onClick={handleUpload}>
                Upload
            </button>
            <a href="/example-askjillai.png">See sample ad file</a>
            <ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

export default UploadAdInput;
