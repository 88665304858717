import React, {useState, useEffect, useRef} from 'react';
import {onAuthStateChanged} from "firebase/auth";
import {signOut} from "firebase/auth";
import {auth, database} from './firebase';
import {useNavigate} from 'react-router-dom';
import {useCommunities} from "./UseCommunities";
import {ref, push} from 'firebase/database'
import Invitations from "./invitations";
import emailService from "./EmailService";
import TagInput from "./TagInput";
import GuidanceInput from "./GuidanceInput";
import UploadInput from "./UploadInput";
import UploadAdInput from "./UploadAdInput";

const Home = (props) => {
    const [userId, setUserId] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [selectedName, setSelectedName] = useState(null);
    const navigate = useNavigate();
    const {communities, isLoading, error} = useCommunities(userId);
    const [emails, setEmails] = useState('');
    const [message, setMessage] = useState('');
    const [invitation, setInvitation] = useState('');
    const [cid, setCid] = useState('');
    const [cname, setCname] = useState('');
    const [isOpenCommunity, setIsOpenCommunity] = useState(true);
    const [isOpenMentor, setIsOpenMentor] = useState(false);
    const [isOpenMentorGuidance, setIsOpenMentorGuidance] = useState(false);
    const [isOpenMentorUpload, setIsOpenMentorUpload] = useState(false);
    const [isOpenMentorAdUpload, setIsOpenMentorAdUpload] = useState(false);
    const [tags, setTags] = useState([]);
    const [urls, setUrls] = useState([]);
    const [founder, setFounder] = useState(false);
    const [agent, setAgent] = useState(false);


    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                setUserId(uid);
                console.log('uid', uid);
                // ...
                console.log("uid", uid)
            } else {
                setUserId(null);
                // User is signed out
                // ...
                console.log("user is logged out and does not exists")
            }
        });

    }, []);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const paramValue = params.get('form');
        setInvitation(paramValue)
        console.log(paramValue);
    }, []);

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }

    function Loader() {
        return <p className="loader">Loading...</p>;
    }

    function ErrorMessage({message}) {
        return (
            <p className="error">
                <span>⛔️</span> {message}
            </p>
        );
    }

    function SucessMessage({message}) {
        return (
            <p className="success">
                <span>🐝️</span> {message}
            </p>
        );
    }

    function Community({community, onSelectCommunity}) {
        console.log("This is the key", community.id);
        return (
            <li onClick={
                () => {
                    onSelectCommunity(community.id, community.name, community.founder, community.type);
                    setIsOpenCommunity(false);
                }

            }>

                <div>
                    <p>
                        <span>🏘️</span>
                    </p>
                    <h3>{community.name}</h3>
                    {community.founder === true && <i>{community.type}</i>}
                </div>
            </li>
        );
    }

    function handleSelectCommunity(id, name, founder, type) {
        console.log('finally ' + id);
        setSelectedId(id);
        setSelectedName(name);
        setFounder(founder);
        if(type == 'agent') {
            setAgent(true);
        }
        else{
            setAgent(false);
        }
        setTags([]);
        // setSelectedId((selectedId) => (id === selectedId ? null : id));
    }

    function takeMeToPublicInvite() {
        navigate(`/PublicInvite?cid=${selectedId}&&cname=${selectedName}`);
        // navigate('/PublicInvite', { state: { cid: selectedId, cname: selectedName } });
    }

    function CommunityList({communities, onSelectCommunity}) {
        return (
            <ul className="list list-communities">
                {communities?.map((community) => (
                    <Community community={community} key={community.id} onSelectCommunity={handleSelectCommunity}/>
                ))}
            </ul>
        );
    }

    function Box({children}) {
        const [isOpen, setIsOpen] = useState(true);


        return (
            <div className="box">
                {/*<button className="btn-toggle" onClick={() => setIsOpen((open) => !open)}>*/}
                {/*    {isOpen ? "–" : "+"}*/}
                {/*</button>*/}

                {/*{isOpen && children}*/}
                {children}
            </div>
        );
    }

    function BoxCommunity({children}) {
        // const [isOpen, setIsOpen] = useState(true);

        return (
            <div className="box">
                {/*<button className="btn-toggle" onClick={() => setIsOpenCommunity((open) => !open)}>*/}
                {/*    {isOpenCommunity ? "–" : "+"}*/}
                {/*</button>*/}

                {isOpenCommunity && children}
            </div>
        );
    }

    function BoxTitle({children}) {

        return (
            <div className="box summary">
                <button className="btn-toggle" onClick={() => setIsOpenCommunity((open) => !open)}>
                    {isOpenCommunity ? "⏬" : "⏫"}
                </button>

                {children}
            </div>
        );
    }

    function BoxMentor({children}) {
        // const [isOpen, setIsOpen] = useState(true);

        return (
            <div className="box">
                {isOpenMentor && children}
            </div>
        );
    }

    function BoxTitleMentor({children}) {

        return (
            <div className="box summary">
                <button className="btn-toggle" onClick={() => setIsOpenMentor((open) => !open)}>
                    {isOpenMentor ? "⏬" : "⏫"}
                </button>

                {children}
            </div>
        );
    }

    function BoxTitleMentorGuidance({children}) {

        return (
            <div className="box summary">
                <button className="btn-toggle" onClick={() => setIsOpenMentorGuidance((open) => !open)}>
                    {isOpenMentorGuidance ? "⏬" : "⏫"}
                </button>

                {children}
            </div>
        );
    }

    function BoxMentorGuidance({children}) {
        // const [isOpen, setIsOpen] = useState(true);

        return (
            <div className="box">
                {isOpenMentorGuidance && children}
            </div>
        );
    }

    function BoxTitleMentorUpload({children}) {

        return (
            <div className="box summary">
                <button className="btn-toggle" onClick={() => setIsOpenMentorUpload((open) => !open)}>
                    {isOpenMentorUpload ? "⏬" : "⏫"}
                </button>

                {children}
            </div>
        );
    }

    function BoxMentorUpload({children}) {
        // const [isOpen, setIsOpen] = useState(true);

        return (
            <div className="box">
                {isOpenMentorUpload && children}
            </div>
        );
    }

    function BoxTitleMentorAdUpload({children}) {

        return (
            <div className="box summary">
                <button className="btn-toggle" onClick={() => setIsOpenMentorAdUpload((open) => !open)}>
                    {isOpenMentorAdUpload ? "⏬" : "⏫"}
                </button>

                {children}
            </div>
        );
    }

    function BoxMentorAdUpload({children}) {
        // const [isOpen, setIsOpen] = useState(true);

        return (
            <div className="box">
                {isOpenMentorAdUpload && children}
            </div>
        );
    }

    async function emailList() {
        console.log('LIST OF EMAILS' + emails + selectedId);

        const dataRef = ref(database, 'membership');


        const user = auth.currentUser;
        if (user) {
            emails.split(",").forEach((email) => {
                push(dataRef, {
                    email: email.trim(),
                    communityId: selectedId,
                    communityName: selectedName
                }).then(() => {
                    setMessage('Emails added successfully');
                    setTimeout(() => {
                        setMessage('');
                        window.location.reload(); // Refresh the page
                    }, 2000); // Hide the message after 2 seconds and refresh the page
                }).catch((error) => {
                    setMessage(`Failed to add emails: ${error.message}`);
                });

            });

            // Send the email
            const emailSvc = new emailService();
            const toEmailList = emails.split(',').map(email => email.trim());
            await emailSvc.sendEmail(toEmailList, selectedName);

        } else {
            console.log('No user signed in.');
        }


    }

    const textAreaRef = useRef(null);

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.focus();
            textAreaRef.current.setSelectionRange(emails.length, emails.length);
        }
    }, [emails]);

    function CommunityDetails({selectedId}) {

        const community = communities.filter((item) => {
            if (item.id === selectedId) return item;
        });

        // console.log(community[0].name);
        return (
            <div className="details">
                {community[0] != null &&
                    (<>
                        <header>
                            <div className="details-overview">
                                {community[0] != null && <h2>Community: {community[0].name}</h2>}
                                <div className="details-overview">

                                    <button onClick={takeMeToPublicInvite}>
                                        Click here to share this public link.
                                    </button>
                                </div>
                                <h3><u>or</u> keep it <u>private</u> and add the emails below to send an invite. </h3>
                                <button className="btn-add" onClick={emailList}>
                                    Send invite ONLY to the emails below
                                </button>
                                {message && <SucessMessage message={message}/>}
                                {/*<h2>{selectedId}</h2>*/}
                            </div>
                        </header>
                        <section>
                            Add emails of people you want to add to the community seperated by comma
                            <textarea ref={textAreaRef} autoFocus value={emails}
                                      onChange={(e) => setEmails(e.target.value)} name="postContent" rows={30} cols={40}
                                      placeholder={"Start typing. As an example Jane.Doe@example.com, jack@example.com"}/>
                        </section>
                    </>)}
                {community[0] == null &&
                    <>
                        <br/><br/>
                        <button className="btn-back">
                            &larr;
                        </button>
                        <h1>Please select a community to get started</h1>
                    </>
                }
            </div>
        );
    }

    return (
        <>
            <main className="main">
                {/*<div className="box">*/}
                {invitation != 'invitations' &&
                    <>
                        <Box>
                            <p>
                                Welcome {props.email} to your Communities & Agents
                            </p>
                            {userId != null && <button className="btn-delete" onClick={handleLogout}>
                                Logout
                            </button>}
                            <br/><br/><br/><br/>
                            <div>
                                <p>Select community or Agent </p>

                            </div>
                            <div>
                                {isLoading && <Loader/>}
                                {!isLoading && !error && (
                                    <CommunityList communities={communities} onSelectCommunity={handleSelectCommunity}/>
                                )}
                                {error && <ErrorMessage message={error}/>}
                            </div>
                            {/*{userId != null && <button className="btn-add" onClick={handleLogout}>*/}
                            {/*    Logout*/}
                            {/*</button>}*/}
                        </Box>
                        <div>
                            {!agent &&
                                <div>
                                <BoxTitle>
                                    <div className="circle">1</div>
                                    <p> Add members to community {selectedName} </p>
                                </BoxTitle>
                                <br/>
                                <BoxCommunity>
                                <CommunityDetails selectedId={selectedId} name={selectedName}/>
                                </BoxCommunity>
                                </div>
                            }
                            {founder &&
                                <BoxTitleMentor>
                                    <div className="circle">{agent? 1: 2}</div>
                                    <p>Create your {agent? "agent": "humanoid"} for : {selectedName}  </p>
                                </BoxTitleMentor>
                            }
                            <BoxMentor>
                                {selectedName && founder &&
                                    <TagInput tags={tags} setTags={setTags} communityId={selectedId}/>}
                            </BoxMentor>
                            <br/>
                            {founder &&
                                <BoxTitleMentorGuidance>
                                    <div className="circle">{agent? 2:3}</div>
                                    <p>Add URL's regarding "{selectedName}" that can guide your
                                        {agent? " agent":" humanoid"}</p>
                                </BoxTitleMentorGuidance>
                            }
                            <BoxMentorGuidance>
                                {selectedName && founder &&
                                    <GuidanceInput urls={urls} setUrls={setUrls} communityId={selectedId}/>}
                            </BoxMentorGuidance>

                            <br/>
                            {founder &&
                                <BoxTitleMentorUpload>
                                    <div className="circle">{agent? 3:4}</div>
                                    <p>Upload documents for "{selectedName}" that can guide your
                                        {agent?" agent":" humanoid"}</p>
                                </BoxTitleMentorUpload>
                            }
                            <BoxMentorUpload>
                                {selectedName && founder &&
                                    <UploadInput communityId={selectedId}/>}
                            </BoxMentorUpload>
                            <br/>
                            {founder && agent &&
                                <BoxTitleMentorAdUpload>
                                    <div className="circle">{agent? 4:4}</div>
                                    <p>Upload advertisement csv documents for "{selectedName}"
                                    </p>
                                </BoxTitleMentorAdUpload>
                            }
                            <BoxMentorAdUpload>
                                {selectedName && founder &&
                                    <UploadAdInput communityId={selectedId}/>}
                            </BoxMentorAdUpload>
                        </div>
                    </>
                }
                {
                    invitation === 'invitations' &&
                    <Invitations email={props.email}/>
                }
                {/*</div>*/}
            </main>
        </>
    )
}

export default Home;